<template>
    <div>
        <!-- v-loading="loading"  -->
        <div class="plugin-item" v-if="item">
            <div class="top-box">
                <div class="left-box">
                    <div class="plugin-icon" :style="'background-image: url(' + item.pic_url + ')'"></div>
                    <div>
                        <div class="plugin-name">
                            <span>{{ item.display_name }}</span>
                            <span class="name-tags">当前版本：{{ item.version }}</span>
                        </div>
                        <div class="plugin-price">￥{{ item.price }}</div>
                        <div v-if="item.desc" class="plugin-desc">{{ item.desc }}</div>
                    </div>
                </div>

                <div class="button-box">
                    <template v-if="item.new_version">
                        <el-button size="small" type="primary" @click="updateVersion" :loading="uploadLoading">升级新版本({{
                            item.new_version }})</el-button>
                    </template>
                    <template v-if="item.status == 'installed'">
                        <el-button size="small" type="primary" @click="toDetail">进入详情</el-button>
                    </template>
                    <template v-else>
                        <template v-if="item.status == 'uninstalled'">
                            <el-button size="small" type="primary" @click="install" :loading="installLoading">
                                安装
                            </el-button>
                        </template>
                        <template v-else-if="item.status == 'not_pay'">
                            <el-button size="small" type="primary" @click="payDialogVisible = !payDialogVisible">付款
                            </el-button>
                        </template>
                        <template v-else>
                            <el-button size="small" type="primary" @click="buy" :loading="buyLoading">购买
                            </el-button>
                        </template>
                    </template>
                </div>
            </div>

            <div class="plugin-content" v-html="item.content"></div>

            <el-dialog title="付款" :visible.sync="payDialogVisible">
                <div>
                    <table v-if="item.order" class="order-table">
                        <colgroup>
                            <col style="width: 80px">
                            <col style="width: auto">
                        </colgroup>
                        <tr>
                            <td>订单编号</td>
                            <td style="font-weight: bold">{{ item.order.order_no }}</td>
                        </tr>
                        <tr>
                            <td>订单金额</td>
                            <td style="text-decoration: line-through; color: #909399">￥{{ item.order.price }}</td>
                        </tr>
                        <tr>
                            <td>支付金额</td>
                            <td style="color: #ff4544;font-weight: bold">￥{{ item.order.pay_price }}</td>
                        </tr>
                        <tr>
                            <td>支付状态</td>
                            <td>
                                <template v-if="item.order.is_pay == 1">
                                    <span style="color: #67C23A">已支付</span>
                                </template>
                                <template v-else>
                                    <span style="color: #909399">未支付</span>
                                </template>
                            </td>
                        </tr>
                        <tr>
                            <td>付款方式</td>
                            <td>请联系管理员完成付款。</td>
                        </tr>
                    </table>
                    <div v-else>暂无订单信息</div>
                </div>
                <span slot="footer">
                    <el-button type="primary" @click="payDialogVisible = false">确定</el-button>
                </span>
            </el-dialog>
        </div>
    </div>
</template>

<script>
import { mapMutations } from 'vuex'
export default {
    data() {
        return {
            loading: true,
            buyLoading: false,
            payDialogVisible: false,
            installLoading: false,
            item: null,
            itenName: '',
            uploadLoading: false
        }
    },
    created() {
        this.itenName = this.$route.query.name
        this.getPluginDetail()
    },
    methods: {
        ...mapMutations(['SET_MENU_TYPE']),
        getPluginDetail() {
            this.$get(this.$apis.appPluginDetail + this.itenName).then(res => {
                console.log(res, 'appPluginDetail');
                if (res.code == 200) {
                    this.item = res.data
                } else {
                    this.$message.error(res.message);
                }
            })
        },
        toDetail() {
            this.SET_MENU_TYPE(this.item.name)
            this.$router.push({ path: this.item.route })
            // window.parent.open('http://' + window.location.hostname + '/' + item.route, '_self');
        },
        buy() {
            this.$confirm('确认购买该插件？', '提示').then(e => {
                this.createOrder();
            }).catch(e => {
            });
        },
        createOrder() {
            this.buyLoading = true;
            this.$post(this.$apis.appPluginBuy + this.itenName).then(res => {
                console.log('appPluginBuy', res);
                if (res.code == 200) {
                    this.$message.success('订单提交成功')
                    location.reload();
                } else {
                    this.buyLoading = false;
                    this.$message.error(res.message);
                }
            })
        },
        install() {
            this.$confirm('确认安装插件？', '提示').then(e => {
                this.doInstall();
            }).catch(e => {
            });
        },
        doInstall() {
            this.installLoading = true;
            let stopContent = '插件安装中，请勿关闭或刷新页面！';
            window.onbeforeunload = function (event) {
                event.returnValue = stopContent;
            };
            if (parent && parent.stopPageClose) {
                parent.stopPageClose(true, stopContent);
            }

            this.$post(this.$apis.appPluginInstall + this.itenName).then(res => {
                console.log('appPluginInstall', res);
                window.onbeforeunload = null;
                if (parent && parent.stopPageClose) {
                    parent.stopPageClose(false);
                }
                if (res.code == 200) {
                    this.$message.success(res.message)
                    location.reload();
                } else {
                    this.installLoading = false;
                    this.$message.error(res.message);
                }
            })
        },
        updateVersion() {
            this.$confirm('确认升级插件？', '提示').then(e => {
                this.doUpdate();
            }).catch(e => {
            });
        },
        doUpdate() {
            this.uploadLoading = true;
            let stopContent = '插件升级中，请勿关闭或刷新页面！';
            window.onbeforeunload = function (event) {
                event.returnValue = stopContent;
            };
            if (parent && parent.stopPageClose) {
                parent.stopPageClose(true, stopContent);
            }

            this.$post(this.$apis.appPluginUpdate + this.itenName).then(res => {
                console.log('appPluginUpdate', res);
                window.onbeforeunload = null;
                if (parent && parent.stopPageClose) {
                    parent.stopPageClose(false);
                }
                if (res.code == 200) {
                    this.$message.success(res.message)
                    location.reload();
                } else {
                    this.uploadLoading = false;
                    this.$message.error(res.message);
                }
            })

        }
    }
}
</script>

<style scoped>
@import './flex.css';

::v-deep .el-dialog__body {
    padding: 30px 20px !important;
}


.plugin-item {
    background: #fff;
    padding: 40px 56px;
    box-shadow: 0 0 1px rgba(0, 0, 0, .15);
    margin: 0 auto;
    border-radius: 5px;
}

.top-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 40px;

}

.left-box {
    display: flex;
    align-items: center;
}

.plugin-icon {
    width: 100px;
    height: 100px;
    background-size: cover;
    background-position: center;
    margin-right: 32px;
}

.plugin-name {
    color: #333333;
    font-size: 24px;
    font-weight: 600;
}

.name-tags {
    color: #999999;
    font-size: 14px;
    padding-left: 12px;
    font-weight: normal;
}

.plugin-price {
    color: #FF7900;
    font-weight: 600;
    margin-top: 11px;
    font-size: 20px;
}

.plugin-desc {
    color: #999999;
    font-size: 14px;
    margin-top: 21px;
}

.plugin-desc {
    font-size: 12px;
    color: #909399;

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.plugin-tag {
    position: absolute;
    right: calc(-50% + 10px);
    top: -5px;
    background: #ff4544;
    color: #fff;
    padding: 0 0;
    width: 100%;
    height: 35px;
    line-height: 45px;
    text-align: center;
    transform: rotate(45deg);
    font-size: 10px;
    box-shadow: 0 0 3px rgba(0, 0, 0, .15);
}

.plugin-content {
    border-top: 1px solid #e3e3e3;
    padding-top: 15px;
    line-height: 1.5;
}

.order-table td {
    padding: 5px 0;
}
</style>